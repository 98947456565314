.inputs-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.input-element {
  margin-bottom: 0;
  flex: 1;

  label {
    font-size: 13.5px;
    font-weight: var(--font-bold);
    color: var(--font-color-light);
    margin-bottom: 0.2rem;
    &--dark {
      color: var(--font-color-dark);
      margin: 0;
    }
  }

  &:not(:last-child) {
    margin-right: 30px;
  }
}
