.home {
  height: 90vh;
  background-color: #f2f2f2;
}

.home__header {
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 45px;
  align-items: center;

  &Buttons {
    display: flex;
    align-items: center;

    p {
      color: #707070;
      font-size: 14px;
      margin-bottom: 0;
    }

    a {
      font-size: 14px;
    }
  }
}

.home__footer {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px;
  background-color: #c4c4c4;
  z-index: 1;
  position: fixed;
  width: 100%;
  bottom: 0;

  p {
    margin: 0;
  }

  &--first {
    display: flex;
    align-items: center;
  }

  &--second {
    font-size: 14px;
    align-items: center;
  }
}

.home__logo {
  color: var(--blue);
  font-size: 18px;

  &:hover,
  :focus,
  :active {
    text-decoration: none;
  }
}
.hero {
  position: relative;
  height: calc(100vh - 60px);
  margin-top: 60px;
  background-color: #f2f2f2;
  display: flex;
  overflow: hidden;
  display: flex;
  align-items: center;

  &__bg {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    bottom: 0;
    background-position: bottom;
    background-position-x: right;

    &1 {
      background-image: url('../../assets/svg/images/hero.svg');
      right: -17px;
      bottom: 44px;
    }
    &2 {
      background-image: url('../../assets/svg/images/hero-2.svg');
    }
  }

  &__btn {
    font-size: 22px;
    font-weight: 400;
    height: 58px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 3px !important;
  }
}

.hero__content {
  max-width: 600px;
  width: 500px;
  margin-left: 7%;
  margin-bottom: 0;
  height: fit-content;
  z-index: 1;
}

.hero__header {
  font-weight: 400;
  font-size: 30px;
}

.hero__text {
  color: #6d6e71;
}

.hero2__bg {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 101%;
  height: 100%;
  transform: translate(0, 232px);
}

.hero__wrapper {
  flex: 1;
  height: calc(100vh - 120px);
}
