.candidate-card {
  min-height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__profilePic {
    width: 80px;
    height: 80px;
    background: bisque;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  &__name {
    font-weight: 500;
  }
  &__info {
    margin-bottom: 10px;

    p {
      font-size: 12px;
      font-weight: 200;
      margin: 0;
      text-align: center;
    }
  }
  &__stage {
    font-size: 12px;
    font-weight: 500;
    text-align: center;

    span {
      font-weight: 400;
    }
  }
}
