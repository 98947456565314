.btn {
  min-width: 110px;
  height: 36px;
  font-size: var(--font-size-medium);
  font-weight: var(--font-bold);
  padding: 0.375rem 1.2rem;
  border-radius: 0.25rem !important;

  &:disabled {
    opacity: 1;
  }
}

.btn-reset,
.btn-reset:active,
.btn-reset:focus {
  border: none;
  background-color: transparent;
  outline: none;
}

.dropdown-toggle::after {
  margin-left: 15px !important;
}

.btn-primary {
  background-color: #0175d7;
  border-color: #0175d7;

  &:hover {
    background-color: #0061b4;
    border-color: #0061b4;
  }

  &.disabled,
  &.disabled:hover,
  &:disabled,
  &:disabled:hover {
    background: #e0e1e2;
    border: #e0e1e2;
    color: white;
    border: 1px solid #e0e1e2;
    cursor: not-allowed;
  }
}

.btn-secondary {
  background-color: #999999;
  border-color: #999999;
  color: white;

  &:hover {
    background-color: #6d6e71;
    border-color: #6d6e71;
  }
}

.btn-success {
  background-color: #21ba45;

  &:hover {
    background-color: #129030;
  }

  &.big {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.btn-light {
  color: var(--font-color-dark);
  border: 1px solid var(--font-color-dark);
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    background-color: #dadada;
    border: 1px solid #333333;
    border-color: #333333 !important;
  }
  &:hover {
    background-color: #dadada;
  }

  &:disabled,
  &:disabled:hover {
    pointer-events: none;
    background-color: transparent;
    border-color: #d6d6d6;
    color: #d6d6d6;
  }
}

.btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-medium);
  padding: 1px 0;
  border-bottom: 1px solid transparent;
}

.btn-edit {
  color: #0175d7;
  &:hover {
    border-bottom: 1px solid #0175d7;
  }
  &:active,
  &:focus {
    color: #0061b4;
    border-bottom: 1px solid #0061b4;
  }
}

.btn-delete {
  color: #ff0000;
  &:hover {
    border-bottom: 1px solid red;
  }
  &:active,
  &:focus {
    color: darkred;
    border-bottom: 1px solid darkred;
  }
}

.btn-save {
  color: #33d205;
  margin-right: 6px;
  &:hover {
    border-bottom: 1px solid #33d205;
  }
  &:active,
  &:focus {
    color: hsl(107, 95%, 30%);
    border-bottom: 1px solid hsl(107, 95%, 30%);
  }

  &.disabled {
    cursor: not-allowed;
    border-color: transparent;
    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
}

.btn-cancel {
  color: #333333;
  &:hover {
    border-bottom: 1px solid #333333;
  }
  &:active,
  &:focus {
    color: black;
    border-bottom: 1px solid black;
  }
}

.btn-next {
  font-size: 15px;
  margin-left: auto;
  &:hover {
    text-decoration: none;
  }
  & span {
    margin-right: 10px;
  }
}

.btn-prev {
  font-size: 15px;
  color: #999999;
  &:hover {
    text-decoration: none;
  }
  & span {
    margin-left: 10px;
  }
}

.btns-space-between {
  display: flex;
  justify-content: space-between;
}

.dropdown-toggle::after {
  margin-left: 10px;
  vertical-align: 7%;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.dropup {
  .dropdown-toggle::after {
    margin-left: 10px;
    vertical-align: 7%;
  }
}

.btn-group-toggle {
  & .btn {
    margin-right: 5px;
    background: transparent;
    color: #b0b0b0;
    border: 1px solid #b0b0b0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #0175d7;
    border-color: #0175d7;
    outline: none;
    box-shadow: none;
  }

  & .btn-primary:hover {
    background-color: #dadada;
    color: #999999;
    border: 1px solid #999999;
  }

  & .btn-primary.disabled:hover {
    background-color: transparent;
    color: #b0b0b0;
    border: 1px solid #b0b0b0;
  }

  & .btn-primary:focus,
  .btn-primary.focus {
    outline: none;
  }
}

.btn-job-primary {
  position: absolute;
  top: -68px;
  right: 165px;
  z-index: 999;
  background-color: transparent;

  &.btn-abs {
    top: -68px;
    right: 166px;
  }

  &.adjusted {
    position: fixed;
    top: 12px;
    right: 221px;
    padding: 6px 19.2px;
    border: 1px solid;

    @media only screen and (min-width: 1510px) {
      top: 12px;
      right: 300px;
    }
    @media only screen and (min-width: 1600px) {
      top: 12px;
      right: 390px;
    }
    @media only screen and (min-width: 1800px) {
      top: 12px;
      right: 450px;
    }
  }
}

.disabled {
  background-color: transparent;
  border-color: #d6d6d6;
  color: #d6d6d6;
}
