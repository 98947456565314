.list {
  &-group {
    margin-bottom: 20px;
  }
}

.card-small {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 0px;
  margin-bottom: 16px;
  font-size: 16px;
  cursor: pointer !important;
  transition: box-shadow 0.2s;

  .btn-text {
    opacity: 0;
    transition: 0.2s;
  }

  &:hover {
    box-shadow: 0px 3px 6px #806d6d1a !important;
    .btn-text {
      opacity: 1;
    }
  }

  & label {
    font-size: var(--font-size-medium);
    display: flex;
    align-items: center;
    max-width: 88%;
    overflow: hidden;
    cursor: pointer;
  }
  &--static {
    label {
      cursor: auto;
    }
    cursor: auto !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}

.stage-editting {
  position: relative;

  input {
    width: 100%;
    padding-right: 180px !important;
  }

  .btn-group {
    position: absolute;
    right: 30px;
    top: 16px;
  }
}

.drag-handle {
  display: flex;
  align-items: center;
  margin-right: 16.8px;

  &:hover {
    cursor: pointer;
  }
}
