.container {
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
}

form {
  position: relative;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 40px 0;
}

.spinner-border {
  position: fixed;
  top: 51%;
  right: 49%;
}

.dropzone {
  color: rgb(109, 110, 113);
  border: 1px dashed #ced4da;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Toastify {
  position: absolute;
}

.dropdown-menu {
  right: 0;
  left: unset;
}
.disable-link {
  color: gray !important;
  pointer-events: none;
}
