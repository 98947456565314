.form {
  &-label {
    font-size: 13.5px;
    font-weight: var(--font-bold);
    color: var(--font-color-light);
    margin-bottom: 0.2rem;
    &--dark {
      color: var(--font-color-dark);
      margin: 0;
    }
  }
  &-control {
    height: auto;
    display: flex;
    align-items: center;
    &:focus {
      border: 1px solid #0175d7;
      box-shadow: none;
    }
  }
  &-control::placeholder {
    color: var(--font-color-lighter);
    font-size: 13px;
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
  }
}

.tagify {
  overflow: hidden;
  height: 38px;
  & span {
    color: var(--font-color-lighter);
    font-size: var(--font-size-base);
    color: hsl(0, 0%, 50%);
    display: flex;
    align-items: center;
  }
  &__input::before {
    font-size: 13px;
  }
  &__input::after {
    display: inherit;
  }
  &__tag {
    margin: 0 5px;
  }
  &__tag > div {
    padding: 0 8px;
  }
}
