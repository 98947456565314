.listJobs {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-top: 45px;

    h2 {
      font-size: 22px;
    }
  }
  &__controls {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-size: 14px;
    }
  }
  &__zeroState {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    opacity: 0.6;
    p {
      max-width: 500px;
      text-align: center;
      font-weight: 300;
    }
  }
}

.job__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }

  &-stages {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }

  &-stage {
    flex: 1;
    display: flex;
    justify-content: center;
    border-right: 1px solid #999999;
    &:last-child {
      border: 0;
    }
    a {
      font-size: 14px;
      color: #333333;
      font-weight: 300;
      padding: 5px 0;
      width: 90%;
      text-align: center;
      span {
        font-weight: 600;
        margin-right: 10px;
      }

      &.selected {
        text-decoration: none;
        background: white;
        border-radius: 5px;
      }

      &:hover {
        text-decoration: none;
        background: white;
        border-radius: 5px;
      }
    }
    a.disabled {
      pointer-events: none;
      user-select: none;
    }
  }
}

.jobCard {
  padding: 30px;

  &__title {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    a {
      font-weight: 400;
      color: var(--font-color-dark);
    }

    svg {
      margin-right: 10px;
      height: 0.8em;
      margin-top: 2px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__stages {
    justify-content: flex-start;
    margin-bottom: 30px;
    li {
      &:not(:first-child) {
        padding-left: 20px;
      }
      flex: unset;
      a {
        width: fit-content;
        position: relative;
        padding: 0;
        padding-bottom: 22px;
        padding-right: 35px;

        span {
          position: absolute;
          top: 25px;
        }
      }
    }
  }

  &__footer {
    p {
      margin: 0;
    }
    p:first-child {
      font-size: 12px;
    }
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
}

.job__content {
  .card-header {
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    padding-top: 15px;
  }
}

.applicant {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 30px 25px;
  box-shadow: 0px 2px 5px #00000026;
  background: white;
  &__avatar {
    width: 57px;
    height: 57px;
    background: grey;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0;
  }
  &__text {
    color: #333333;

    * {
      margin: 0;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: 500;
  }

  &__summary {
    font-size: 11px;
    font-weight: 400;
  }

  &__small {
    font-size: 10px;
    font-weight: 300;
    color: #6d6e71;
  }
}

.application {
  &__header {
    display: flex;
    justify-content: space-between;

    &-controls {
      display: flex;
      align-items: center;

      .disqualify:hover {
        cursor: pointer;
      }

      > * {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
  > .card {
    background: #f2f2f2;
    box-shadow: none;

    .card-header {
      background: inherit;
    }
    .card-body {
      padding-top: 10px;
    }

    .card {
      border-radius: 3px;
    }
  }

  .applicant {
    padding: 0;
    background: white;
    box-shadow: none;
    &__avatar {
      width: 90px;
      height: 90px;
      margin-right: 30px;
    }

    &__text {
      * {
        line-height: 1;
        margin-bottom: 10px;
      }
    }

    &__name {
      font-size: 18px;
      font-weight: 600;
    }
    &__summary {
      font-size: 14px;
    }
    &__small {
      font-size: 12px;
    }
  }

  .applicants__nav {
    justify-content: flex-start;
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 20px;

    li {
      flex: 0;

      a {
        padding: 0 15px 15px 15px;
      }
    }
  }
}

.applicants__nav {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0;
  font-size: 12px;
  margin-bottom: 0;
  border-bottom: 1px solid #b0b0b0;

  li {
    display: flex;
    justify-content: center;
    flex: 1;

    &:first-child {
      border-bottom: 2px solid #333333;
    }

    &:not(:first-child) {
      color: #b0b0b0;
      pointer-events: none;
      user-select: none;
    }

    a {
      font-weight: 400;
      padding: 0 13px;
      padding-bottom: 15px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.custom-link {
  &:hover,
  &:focus,
  &:visited {
    text-decoration: none;
  }

  &.selected {
    .applicant {
      background: #f2f2f2;
    }
  }
  .disabled {
    pointer-events: none !important;
  }
}

.contact-details {
  p {
    display: flex;
    align-items: center;
  }
}

.application__answers {
  .card {
    box-shadow: none;
    .card-header {
      font-weight: 500;
    }
  }

  &-question {
    color: #6d6e71;
    font-size: 16px;
  }
  &-answer {
    color: #6d6e71;
    font-size: 14px;
  }
}

.toast {
  position: fixed;
  top: 70px;
  right: 25px;
  width: 275px;
}
