@import 'bootstrap';

@import 'vendors/normalize';

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'base/base';
@import 'base/fonts';
@import 'base/helpers';
@import 'base/typography';

@import 'layout/grid';
@import '@yaireo/tagify/src/tagify';

@import 'global';
@import 'components/dashboard';
@import 'components/top-nav';
@import 'components/side-nav';
@import 'components/search-bar';
@import 'components/profile-dropdown';
@import 'components/btn';
@import 'components/header';
@import 'components/card';
@import 'components/card-small';
@import 'components/form';
@import 'components/quill';
@import 'components/workflow-stage';
@import 'components/error';
@import 'components/modal';
@import 'components/questions';
@import 'components/job-detail';
@import 'components/job';
@import 'components/home';
@import 'components/authentication';
@import 'components/settings';
@import 'components/candidate-card';
@import 'components/candidates-filters';
@import 'base/overrides';
