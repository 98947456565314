.side-nav {
  justify-content: center;
  display: flex;
  width: var(--side-nav-width);
  height: 100%;
  position: fixed;
  background: var(--color-primary-dark);
  z-index: 100;
  transition: width 0.2s;

  &__item-container {
    padding: 0;
    margin-bottom: 25px;

    &.active {
      .side-nav__item {
        background-color: var(--color-primary-lighter);

        &::after {
          content: '';
          border: 7px solid transparent;
          border-right: 7px solid var(--color-secondary-dark);
          position: absolute;
          right: -12px;
        }
      }
      .side-nav__item-text {
        color: var(--color-primnary-light);
      }

      .side-nav__item-icon {
        svg path {
          fill: var(--color-primary-light);
        }
      }
    }
  }

  &__item {
    position: relative;
    width: var(--side-nav-item-width);
    height: var(--side-nav-item-width);
    display: flex;
    align-items: center;
    border-radius: 10px;
    transition: width 0.2s;

    &-icon {
      position: absolute;
      left: 8px;
      width: 30px;
      display: flex;
      justify-content: center;
    }

    &:active {
      background-color: var(--color-primary-lighter);
    }

    &-text {
      position: absolute;
      left: 50px;
      font-size: 0;
      color: var(--color-secondary-light);
      font-weight: var(--font-bold);
      opacity: 0;
    }
  }

  &:hover {
    width: var(--side-nav-expanded-width);

    .side-nav__item {
      width: var(--side-nav-item-expanded-width);

      &:hover {
        * {
          color: var(--color-primary-light);

          path {
            fill: var(--color-primary-light);
          }
        }
      }
    }

    .side-nav__item-text {
      font-size: var(--font-size-base);
      opacity: 0.7;
      margin-left: 5px;
      transition: opacity 0.3s ease-out;
    }
  }

  &__items {
    margin-top: 90px;
  }
}
