.header-wrapper {
  position: sticky;
  top: 0;
  background: var(--color-secondary-dark);
  z-index: 2;
  margin-bottom: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;

  &__text {
    font-size: var(--font-size-larger);
    font-weight: var(--font-bold);
    text-overflow: ellipsis;
    flex-grow: 0;
    overflow: hidden;
    margin-right: 65px;
    min-width: 220px;
    max-width: 220px;
    white-space: nowrap;
  }

  &__nav {
    & .nav-link {
      font-size: 15px;
      color: #999999;

      &:hover,
      &.active {
        color: #0175d7;
      }
    }

    & .nav-item:not(:last-child) {
      position: relative;
      margin-right: 65px;
      &::after {
        content: '';
        width: 65px;
        height: 1px;
        background-color: #999999;
        position: absolute;
        top: 50%;
        left: 100%;
      }
    }
  }

  &__buttons {
    display: flex;
    margin-left: auto;
    & > button {
      margin-right: 12px;
    }
  }
}

a {
  font-weight: var(--font-bold);
}

div[aria-disabled='true'] {
  cursor: not-allowed;
  user-select: none;

  a {
    color: #b0b0b083 !important;
    pointer-events: none;
    &:hover {
      color: #b0b0b083;
    }
  }
}
