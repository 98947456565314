.candidates-filter {
  margin-bottom: 20px;
  &__label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }
  &__options {
    .form-group {
      font-size: 12px;
      margin-bottom: 5px;

      .form-check-input {
        margin-top: 3px;
      }
    }
  }
}
