.dashboard {
  &__content {
    margin-left: var(--side-nav-width);
  }

  &__main-content {
    background: var(--color-secondary-dark);
    min-height: 100vh;
    padding-bottom: 100px;
  }

  &__zero-state {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, 35%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    svg {
      margin-bottom: 22px;
    }

    h3 {
      font-size: 25px;
    }

    p {
      font-size: 14px;
      color: #6d6e71;
      margin: 1rem;
      max-width: 500px;
    }

    button {
      font-weight: 500;
    }
  }
}

.page-header {
  margin: 0;
  padding: 30px;
}
