.question {
  &-container {
    box-shadow: none;
    background-color: #f9f9f9;
    border-radius: 8px;
    font-size: var(--font-size-medium);
    position: relative;
  }
  &__body {
    padding: 20px;
  }
  &__header {
    font-size: inherit;
    display: flex;
    margin-bottom: 20px;
    padding-left: 30px;

    .drag-handle {
      position: absolute;
      top: 22px;
      left: 20px;
    }
  }

  &__headline {
    font-weight: var(--font-medium);
    margin-bottom: 10px;
    padding-left: 30px;

    &--editting {
      margin-bottom: 12px;
      padding-left: 30px;
      font-size: var(--font-size-medium);
    }
  }
  &__btns {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__add-option {
    color: #707070;

    p {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-left: 35px;
      position: relative;
      &::before {
        content: '+';
        font-size: 27px;
        color: #bebebe;
        line-height: 0.5;
        position: absolute;
        left: -35px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__options {
    list-style: none;
    padding: 0 30px;
    input {
      font-size: var(--font-size-medium);
    }
  }

  &__option {
    display: flex;
    margin-bottom: 15px;
    width: 50%;

    &-input {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      input {
        padding-right: 35px;
      }

      button {
        position: absolute;
        right: 10px;

        &:hover svg {
          color: #b3b3b3;
        }
      }
    }
  }

  &__answers {
    margin-left: 22px;
  }

  &__divider {
    margin: 27px 0 17px 0;
  }

  &__add {
    .dropdown-menu {
      width: 225px;

      .dropdown-item {
        padding: 0.5rem 1.5rem;
        svg {
          margin-right: 20px;
        }
      }
    }
  }
}
