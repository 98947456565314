.quill {
  height: 240px;
}

.ql {
  &-container {
    height: calc(100% - 40px) !important;
    border-radius: 0 0 3px 3px;
  }
  &-toolbar {
    background-color: #f8fafb;
    height: 40px;
    border-radius: 3px 3px 0 0;
  }
}
