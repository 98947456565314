:root {
  // --- COLORS --- //
  --color-primary-dark: #012349;
  --color-primary-light: #006fd9;
  --color-primary-lighter: #006fd94d;
  --color-secondary-dark: #f2f2f2;
  --color-secondary-light: #ffffff;

  // --- TYPOGRAPHY --- //

  --font-size-small: 13.5px;
  --font-size-medium: 14px;
  --font-size-base: 16px;
  --font-size-large: 17px;
  --font-size-larger: 20px;
  --font-medium: 500;
  --font-bold: 600;
  --font-bolder: 700;
  --font-color-dark: #333333;
  --font-color-light: #333333de;
  --font-color-lighter: #b5b5b5;

  // --- SPACING --- //

  --padding-base-vertical: 15px;
  --padding-base-horizontal: 30px;

  // ---COMPONENTS --- //

  --top-nav-height: 60px;
  --side-nav-width: 70px;
  --side-nav-padding: 0 10px;
  --side-nav-expanded-width: 200px;
  --side-nav-item-width: 45px;
  --side-nav-item-expanded-width: 175px;
}
