.modal {
  &-header .close:focus {
    outline: none;
  }
  &-content {
    border-radius: 10px;

    & .form-group {
      margin-bottom: 0;
    }
  }
  &-title {
    font-size: var(--font-size-large);
    font-weight: var(--font-bold);
  }
  &-footer {
    border-top: none;
    padding: 0 0.75rem 1rem 0.75rem;
  }
}
