.profile-dropdown,
.profile-dropdown:hover,
.profile-dropdown:active,
.profile-dropdown:focus {
  color: black !important;
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  min-width: 35px !important;
  height: 35px !important;
  padding: 0 !important;

  &::after {
    content: none;
  }
}

.profile-dropdown__text {
  font-size: var(--font-size-base);
  color: #333333;
  font-weight: var(--font-bold);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ececec;
  border-radius: 50%;
  color: black;
}
