.search-bar {
  position: relative;
  margin-right: 5px;

  &__input {
    font-size: var(--font-size-small);
    border: 1px solid #dededf;
    padding: 7px;

    &::placeholder {
      color: #cecece;
    }
  }

  &__btn {
    background: none;
    border: none;
    position: absolute;
    right: 11px;
    top: 7px;

    &:hover,
    &:active,
    &:focus {
      background: none;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  &__icon {
    color: #9a9a9a;
    width: 14px !important;
    height: 13px;
  }
}
