.top-nav {
  display: flex;
  background: var(--color-secondary-light);
  height: var(--top-nav-height);

  &__brand {
    font-size: var(--font-size-large);
    font-weight: var(--font-bold);

    a {
      color: var(--font-color-dark);

      &:hover,
      &:focus,
      &:active {
        color: var(--font-color-dark);
        text-decoration: none;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    padding: 0;
  }

  &__right {
    display: flex;
    align-items: center;
  }
}
