.card {
  border: none;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 10px #0000001a;
  &-body {
    padding: 30px;
  }

  &-header {
    font-size: 18px;
    background-color: white;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }

  &-title {
    font-weight: var(--font-bold);
    font-size: var(--font-size-large);
  }

  &-subtitle {
    color: #757575;
    font-size: var(--font-size-small);
    margin-top: 25px;
    margin-bottom: 10px;
  }
}

.cards-container {
  background: white;
  height: 1000px;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
}
