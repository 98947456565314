.settings__container {
  display: flex;
  padding-top: 35px;

  .col-lg-3 {
    .card-body {
      padding: 20px;
    }
  }

  .card-header {
    font-weight: 500;
  }
}

.settings__nav {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;

    .active {
      color: #0175d7;
    }

    a {
      color: #3e3e3d;
      font-size: 14px;

      &:hover,
      :focus,
      :active,
      :visited {
        text-decoration: none;
      }
    }
  }
}

.departments {
  &__search {
    width: 245px;
    &::placeholder {
      color: #cecece;
      font-size: 14px;
    }

    &-container {
      position: relative;

      svg {
        position: absolute;
        top: 11px;
        right: 13px;
        color: #9a9a9a;
        width: 13px;
      }
    }
  }
}
