.auth {
  border-radius: 8px;

  .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: white;
    font-weight: 600;
    font-size: 18px;
  }

  .card-body {
    padding: 20px;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: var(--font-color-light);

    a {
      color: var(--font-color-light);
    }

    .form-check-input {
      margin-top: 3px;
    }
  }
}

.auth__error {
  color: red;
  font-size: 14px;
}

.auth__btn {
  float: right;
}

.signup {
  &__cardsWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__cards {
    display: flex;

    a:hover,
    :active,
    :focus {
      text-decoration: none;
    }
  }

  &__card {
    height: 380px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 40px;

    &:first-child {
      margin-right: 40px;
    }
    a {
      color: var(--font-color-primary);
    }

    &_bg1 {
      width: 100%;
      height: 200px;
      background: url('../../assets/svg/images/company.svg') no-repeat center;
      background-size: contain;
    }
    &_bg2 {
      width: 100%;
      height: 200px;
      background: url('../../assets/svg/images/employee.svg') no-repeat center;
      background-size: contain;
    }
    &_title {
      font-size: 18px;
      font-weight: 100;
      color: #333333;
      margin-top: 20px;
      margin-bottom: 15px;
      font-weight: 500;

      &:hover,
      :focus,
      :active,
      :visited {
        color: #333333;
        text-decoration: none;
      }
    }
    &_text {
      font-size: 12px;
      color: #a5a5a5;
      font-weight: 400;
      text-align: center;
    }
  }
}
